import * as yup from "yup";

export const loginValidationSchema = yup.object({
  login: yup.string().required("Pole jest wymagane"),
  password: yup.string().required("Pole jest wymagane"),
});

export const loginInitialValues = {
  login: "",
  password: "",
};
