import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { AppRadioOption } from "../../common/components/AppRadio";
import Filter from "../../common/components/Filter";
import UserCard from "./UserCard";
import { orderBy as _orderBy } from "lodash";
import { useGetUsersQuery, useUpdateUserStatusMutation } from "./userApi";
import { UserInterface, UserStatusInterface } from "../../interfaces/user";

const filterOptions: AppRadioOption[] = [
  {
    name: "po nicku rosnąco",
    value: "nick-asc",
  },
  {
    name: "po nicku malejąco",
    value: "nick-desc",
  },
  {
    name: "po aktywności rosnąco",
    value: "lastActivity-asc",
  },
  {
    name: "po aktywności malejąco",
    value: "lastActivity-desc",
  },
  {
    name: "po czasie ukończenia rosnąco",
    value: "finishTimeSum-asc",
  },
  {
    name: "po czasie ukończenia malejąco",
    value: "finishTimeSum-desc",
  },
];

const UserList = () => {
  const [filteredUsers, setFilteredUsers] = useState<UserInterface[]>([]);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [query, setQuery] = useState<string>("");
  const [selectedFilterOption, setSelectedFilterOption] = useState<string>("");
  const { data: userData } = useGetUsersQuery();
  const [updateUserStatus] = useUpdateUserStatusMutation();

  useEffect(() => {
    let filteredUsers: UserInterface[] = users.filter(
      (user: UserInterface) =>
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.nick.toLowerCase().includes(query.toLowerCase()) ||
        !query,
    );

    if (selectedFilterOption) {
      const fieldName: string = selectedFilterOption.split("-")[0];
      const orderBy: any = selectedFilterOption.split("-")[1];
      filteredUsers = _orderBy(filteredUsers, [fieldName], [orderBy]);
    }
    setFilteredUsers(filteredUsers);
  }, [query, selectedFilterOption]);

  useEffect(() => {
    if (userData && userData.documents) {
      setUsers(userData.documents);
      setFilteredUsers(userData.documents);
    }
  }, [userData]);

  const handleOnChangeStatus = (userIndex: number): void => {
    const userData = users[userIndex];

    if (userData) {
      const changetatusData: UserStatusInterface = {
        isActivated: !userData.isActivated,
      };

      updateUserStatus({ userId: userData.id, data: changetatusData });
    }
  };

  const getUsersView = (): React.ReactNode => {
    if (!filteredUsers || !filteredUsers.length) {
      return (
        <tr>
          <td colSpan={10}>Brak wyników</td>
        </tr>
      );
    }

    return filteredUsers.map((user: any, index: number) => {
      return (
        <UserCard
          key={`single-user-${index}`}
          user={user}
          index={index}
          handleToggleUserStatus={handleOnChangeStatus}
        />
      );
    });
  };

  return (
    <div className="users-wrapper">
      <Filter
        filterOptions={filterOptions}
        query={query}
        setQuery={setQuery}
        selectedFilteredOption={selectedFilterOption}
        onChange={setSelectedFilterOption}
      />
      <Table bordered>
        <thead>
          <tr>
            <th>Lp</th>
            <th>Nick</th>
            <th>E-mail</th>
            <th>Data dołączenia</th>
            <th>Ilość zebranych kropli</th>
            <th>Ilość odwiedzonych punktów</th>
            <th>Ostatnia aktywność</th>
            <th>Czas ukończenia</th>
            <th>Czy aktywny</th>
            <th>Więcej</th>
          </tr>
        </thead>
        <tbody>{getUsersView()}</tbody>
      </Table>
    </div>
  );
};

export default UserList;
