import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { AppStorageTypes } from "../../app/store";
import { useCheckAuthQuery } from "../login/loginApi";
import Footer from "./Footer";
import Header from "./Header";

const App = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, error, isLoading } = useCheckAuthQuery();

  useEffect(() => {
    if (error) {
      localStorage.removeItem(AppStorageTypes.TOKEN);
      navigate("/login");
    }

    if (data && data.success && location.pathname === "/login") {
      navigate("/home");
    }

    if (location.pathname === "" || location.pathname === "/") {
      navigate("/home");
    }
  }, [data, error]);

  return (
    <>
      {!isLoading && (
        <Container fluid>
          <Header />
          <Container>{outlet}</Container>
          <Footer />
        </Container>
      )}
    </>
  );
};

export default App;
