import React from "react";
import { Form } from "react-bootstrap";

type AppRadioProp = {
  label: string;
  name: string;
  options: AppRadioOption[];
  register: any;
  spacing?: number;
  customClass?: string;
  error?: string | null;
};

export type AppRadioOption = {
  name: string;
  value: string;
};

const AppRadio = ({ label, name, options, register, spacing = 3, customClass, error }: AppRadioProp) => {
  const getOptionsView = (): React.ReactNode => {
    return options.map((option: AppRadioOption, index: number) => {
      return (
        <Form.Check
          {...register(name)}
          key={`single-radio-option-${index}`}
          type="radio"
          value={option.value}
          id={option.value}
          label={option.name}
        />
      );
    });
  };

  return (
    <Form.Group className={`app-radio mb-${spacing} ${customClass ? customClass : ""} ${error ? "has-error" : ""}`}>
      <Form.Label>{label}</Form.Label>
      <div className="app-radio-option-wrapper">{getOptionsView()}</div>
      {error && <p className="error-text">{error}</p>}
    </Form.Group>
  );
};

export default AppRadio;
