import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../app/apiConfig";
import { ApiResponse } from "../../interfaces/application";
import { LoginInterface } from "../../interfaces/auth";
import { UserLoginDataInterface } from "../../interfaces/user";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: baseQuery,
  tagTypes: ["Login"],
  endpoints: (build) => ({
    login: build.mutation<ApiResponse<UserLoginDataInterface>, LoginInterface>({
      query: (data) => ({
        url: `auth/login`,
        method: "POST",
        body: data,
      }),
    }),
    checkAuth: build.query<ApiResponse<unknown>, void>({
      query: () => ({
        url: `auth/checkAuth`,
      }),
    }),
  }),
});

export const { useLoginMutation, useCheckAuthQuery } = loginApi;
