import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../app/apiConfig";
import { ApiResponse } from "../../interfaces/application";
import { GameInterface, GameStatusInterface } from "../../interfaces/game";

export const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: baseQuery,
  tagTypes: ["Games", "Game"],
  endpoints: (build) => ({
    getGames: build.query<ApiResponse<GameInterface>, void>({
      query: () => ({
        url: `game`,
      }),
      providesTags: ["Games"],
    }),
    getGame: build.query<ApiResponse<GameInterface>, string>({
      query: (gameId) => ({
        url: `game/${gameId}`,
      }),
      providesTags: ["Game"],
    }),
    addGame: build.mutation<ApiResponse<GameInterface>, GameInterface>({
      query: (data) => ({
        url: `game`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Game"],
    }),
    editGame: build.mutation<ApiResponse<GameInterface>, { gameId: string; data: GameInterface }>({
      query: ({ gameId, data }) => ({
        url: `game/${gameId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Game"],
    }),
    updateGameStatus: build.mutation<ApiResponse<GameStatusInterface>, { gameId: number; data: GameStatusInterface }>({
      query: ({ gameId, data }) => ({
        url: `game/gameStatus/${gameId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Game"],
    }),
  }),
});

export const {
  useGetGamesQuery,
  useLazyGetGamesQuery,
  useLazyGetGameQuery,
  useAddGameMutation,
  useEditGameMutation,
  useUpdateGameStatusMutation,
} = gameApi;
