import React, { useState } from "react";
import ConfirmModal from "../../common/components/ConfirmModal";
import Icon, { IconTypes } from "../../common/components/Icon";
import { GameInterface, StatusEnum } from "../../interfaces/game";
import { useUpdateGameStatusMutation } from "../game/gameApi";

type StartGameProp = {
  currentGame: GameInterface;
  handleSetCurrentGame: (currentGame: GameInterface) => void;
};

const ModalText = () => {
  return (
    <>
      <p>Czy na pewno chcesz rozpocząć grę?</p>
      <p>Upewnij się, że wszystkie punkty są poprawnie uzupełnione.</p>
    </>
  );
};

const StartGame = ({ currentGame, handleSetCurrentGame }: StartGameProp) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateGameStatus] = useUpdateGameStatusMutation();

  const handleStartNewGame = (): void => {
    if (currentGame && currentGame.id) {
      updateGameStatus({ gameId: currentGame.id, data: { status: StatusEnum.STARTED } });
      const gameTmp = { ...currentGame };
      gameTmp.status = StatusEnum.STARTED;
      handleSetCurrentGame(gameTmp);
      setShowModal(false);
    }
  };

  return (
    <div className="start-game-wrapper">
      <p>Czas rozpocząć grę!</p>
      <p>Gra została utworzona, ale nie jest jeszcze dostępna dla użytkowników. Czy chcesz ją rozpocząć?</p>
      <div onClick={() => setShowModal(true)} className="start-new-game-button">
        <Icon iconName={IconTypes.DropSVG} size={56} />
        <span>Rozpocznij grę</span>
      </div>
      <ConfirmModal
        show={showModal}
        onChange={handleStartNewGame}
        cancelTitle="Jeszcze sie upewnię"
        confirmTitle="Tak, chcę!"
        modalText={<ModalText />}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default StartGame;
