import { BaseInterface } from "./base";
import { RiddleQuestionInterface } from "./riddleQuestion";

export interface RiddleInterface extends BaseInterface {
  localizationId?: string;
  riddleType: RiddleTypeEnum;
  riddleQuestions: RiddleQuestionInterface[]
}

export enum RiddleTypeEnum {
  QUIZ = 'QUIZ',
  PUZZLE = 'PUZZLE',
  REBUS = 'REBUS'
}
