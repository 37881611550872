import React from "react";
import { Form } from "react-bootstrap";
import { Control, FieldErrors, useFieldArray, UseFormRegister, useWatch } from "react-hook-form";
import AppButton from "../../common/components/AppButton";
import AppCheckbox from "../../common/components/AppCheckbox";
import AppInput from "../../common/components/AppInput";
import Icon, { IconTypes } from "../../common/components/Icon";
import { RiddleAnswerInterface } from "../../interfaces/riddleAnswer";
import { get as _get } from "lodash";
import { RiddleTypeEnum } from "../../interfaces/riddle";

type RiddleAnswersProp = {
  riddleIndex: number;
  questionIndex: number;

  control: Control<any>;
  errors: FieldErrors | string | null;
  register: UseFormRegister<any>;
};

const RiddleAnswers = ({ control, register, errors, riddleIndex, questionIndex }: RiddleAnswersProp) => {
  const { fields, append, remove } = useFieldArray({
    name: `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers`,
    control,
  });

  const riddleType = useWatch({
    control,
    name: `riddles[${riddleIndex}].riddleType`,
  });

  const riddleQuestionAnswers = useWatch({
    control,
    name: `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers`,
  });

  const isShownAddButton = (): boolean => {
    if (riddleType === RiddleTypeEnum.REBUS && riddleQuestionAnswers.length > 0) {
      return false;
    }
    return true;
  };

  const handleAddRiddleQuestionAnswer = (): void => {
    const newRiddleQuestionAnswer: RiddleAnswerInterface = {
      answerName: "",
      isTrue: false,
    };

    if (riddleType === RiddleTypeEnum.REBUS) {
      newRiddleQuestionAnswer.isTrue = true;
    }

    append(newRiddleQuestionAnswer);
  };

  const handleRemoveRiddleQuestionAnswer = (riddleQuestionAnswerIndex: number): void => {
    remove(riddleQuestionAnswerIndex);
  };

  return (
    <div className="riddle-questions-answers-wrapper">
      <Form.Label>Odpowiedzi</Form.Label>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="single-riddle-question-answer-wrapper">
            <div className="inputs-wrapper">
              <AppInput
                spacing={0}
                register={register}
                placeholder="Uzupełnij odpowiedź"
                name={`riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers[${index}].answerName`}
                error={_get(
                  errors,
                  `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers[${index}].answerName.message`,
                  null,
                )}
              />
              <AppCheckbox
                spacing={0}
                label="jest poprawna"
                isDisabled={riddleType === RiddleTypeEnum.REBUS}
                register={register}
                name={`riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers[${index}].isTrue`}
              />
              <div className="remove-button-wrapper" onClick={() => handleRemoveRiddleQuestionAnswer(index)}>
                <Icon iconName={IconTypes.CloseSVG} />
              </div>
            </div>
          </div>
        );
      })}
      {isShownAddButton() && (
        <div onClick={handleAddRiddleQuestionAnswer}>
          <AppButton title="Dodaj kolejną odpowiedź" />
        </div>
      )}
      {_get(errors, `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers.message`, null) && (
        <p className="error-text">
          {_get(errors, `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionAnswers.message`, null)}
        </p>
      )}
    </div>
  );
};

export default RiddleAnswers;
