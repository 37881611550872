import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { get as _get } from "lodash";
import { loginInitialValues, loginValidationSchema } from "./loginValidation";
import { useLoginMutation } from "./loginApi";
import AppButton from "../../common/components/AppButton";
import AppInput from "../../common/components/AppInput";
import { IconTypes } from "../../common/components/Icon";
import Navigation from "../app/Navigation";
import { UserTypeEnum } from "../../interfaces/user";
import { AppStorageTypes } from "../../app/store";

const Login = () => {
  const navigate = useNavigate();
  const [loginIn, { data, error, isLoading }] = useLoginMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitialValues,
    resolver: yupResolver(loginValidationSchema),
  });

  useEffect(() => {
    const errorMessage: string | null = _get(error, "data.message", null);
    if (errorMessage) {
      setError("login", { type: "custom", message: errorMessage });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.success && _get(data, "document.user.userType") === UserTypeEnum.IS_ADMIN) {
        localStorage.setItem(AppStorageTypes.TOKEN, _get(data, "document.access_token", ""));
        navigate("/home");
      } else {
        setError("login", { type: "custom", message: "Dane autoryzacyjne są niepoprawne" });
      }
    }
  }, [data]);

  const onSubmit = handleSubmit((data): void => {
    loginIn(data);
  });

  return (
    <div>
      <Navigation
        showLine={true}
        navigationData={{
          iconName: IconTypes.DropGreySVG,
          moduleName: "Logowanie do panelu administracji",
        }}
      />
      <div className="login-form-wrapper">
        <Form className="login-form" onSubmit={onSubmit}>
          <AppInput
            register={register}
            label="E-mail"
            placeholder="Uzupełnij e-mail"
            name="login"
            error={_get(errors, "login.message", null)}
          />
          <AppInput
            register={register}
            label="Hasło"
            placeholder="Uzupełnij hasło"
            type="password"
            name="password"
            spacing={5}
            error={_get(errors, "password.message", null)}
          />
          <div className="button-wrapper">
            <AppButton type="submit" title="Zaloguj się" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
