import React from "react";
import { Form } from "react-bootstrap";

type AppCheckboxProp = {
  name: string;
  label: string;
  register: any;
  spacing?: number;
  customClass?: string;
  isDisabled?: boolean;
  error?: string;
};

export type AppRadioOption = {
  name: string;
  value: string;
};

const AppCheckbox = ({
  name,
  label,
  register,
  spacing = 3,
  customClass,
  isDisabled = false,
  error,
}: AppCheckboxProp) => {
  return (
    <Form.Group className={`app-checkbox mb-${spacing} ${customClass ? customClass : ""} ${error ? "has-error" : ""}`}>
      <div className="app-checkbox-option-wrapper">
        <Form.Check {...register(name)} type="checkbox" label={label} disabled={isDisabled} />
      </div>
      {error && <p className="error-text">{error}</p>}
    </Form.Group>
  );
};

export default AppCheckbox;
