import { BaseInterface } from "./base";
import { UserHistoryInterface } from "./userHistory";

export interface UserInterface extends BaseInterface {
  id: number;
  nick: string;
  login: string;
  email: string;
  userType?: UserTypeEnum;
  isActivated?: boolean;
  privacyAcceptance: boolean;
  regulationAcceptance: boolean;
  password: string;
  userHistory: UserHistoryInterface[];
  finishTimeSum?: string;
}

export enum UserTypeEnum {
  IS_ADMIN = "IS_ADMIN",
  IS_USER = "IS_USER",
}

export interface UserLoginDataInterface {
  user: UserInterface;
  access_token: string;
}

export interface UserStatusInterface {
  isActivated: boolean;
}
