import React from "react";
import { Form } from "react-bootstrap";

type AppInputProps = {
  placeholder?: string;
  value: string;
  onChange: any;
};

const AppSearchInput = ({ placeholder, value, onChange }: AppInputProps) => {
  return (
    <div>
      <Form.Control type="text" value={value} placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default AppSearchInput;
