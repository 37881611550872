import * as yup from "yup";
import { LocalizationInterface, LocalizationTypeEnum } from "../../interfaces/localization";

export const localizationValidationSchema = yup.object({
  id: yup.number(),
  title: yup.string().required("Pole jest wymagane"),
  description: yup.string().required("Pole jest wymagane"),
  coordinates: yup.string().required("Pole jest wymagane"),
  localizationType: yup.string().required("Pole jest wymagane"),
  distance: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .when("localizationType", (localizationType, schema) => {
      if (localizationType[0] === LocalizationTypeEnum.IN_NEAR) {
        return schema.required("Pole jest wymagane");
      }
      return schema;
    }),
  photos: yup.array().required("Pole jest wymagane"),
  riddles: yup
    .array(
      yup.object().shape({
        riddleType: yup.string().required("Pole jest wymagane"),
        riddleQuestions: yup
          .array(
            yup.object().shape({
              questionName: yup.string().required("Pole jest wymagane"),
              questionAnswers: yup
                .array(
                  yup.object().shape({
                    answerName: yup.string().required("Pole jest wymagane"),
                    isTrue: yup.boolean(),
                  }),
                )
                .required("Pole jest wymagane")
                .min(1, "Musisz dodać przynajmniej jedną odpowiedź"),
            }),
          )
          .required("Pole jest wymagane")
          .min(1, "Musisz dodać przynajmniej jedno pytanie"),
      }),
    )
    .required("Pole jest wymagane")
    .min(1, "Musisz dodać przynajmniej jedną zagadkę"),
});

export const localizationInitialValues: LocalizationInterface = {
  title: "",
  description: "",
  coordinates: "",
  localizationType: LocalizationTypeEnum.IN_NEAR,
  distance: null,
  photos: [],
  riddles: [],
};
