import React from "react";
import LogoWfoisSVG from "../../assets/logo-wfois.svg";

const Footer = () => {
  return (
    <footer className="footer-header">
      <p>
        Projekt pn. "Woda i lokalne środowisko ponad wszystko" realizowany przy wsparciu Wojewódzkiego Funduszu Ochrony
        Środowiska i Gospodarki Wodnej.
      </p>
      <img src={LogoWfoisSVG} alt="wfois-logo" />
    </footer>
  );
};

export default Footer;
