import React, { useState } from "react";
import { UserInterface } from "../../../../shared/user";
import AppButton from "../../common/components/AppButton";
import Icon, { IconTypes } from "../../common/components/Icon";
import UserCardHistory from "./UserCardHistory";

type UserProp = {
  user: UserInterface;
  index: number;
  handleToggleUserStatus: (userIndex: number) => void;
};

const UserCard = ({ user, index, handleToggleUserStatus }: UserProp) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const getStatusButtonView = (): React.ReactNode => {
    if (user.isActivated) {
      return <AppButton customClass="action-button deactivate-user-button" title="dezaktywuj" />;
    } else {
      return <AppButton customClass="action-button activate-user-button" title="aktywuj" />;
    }
  };

  const handleChangeStatus = (): void => {
    handleToggleUserStatus(index);
  };

  const getFinishTimeSumString = (): string => {
    if (user.finishTimeSum) {
      const totalMinutes = Math.floor(user.finishTimeSum / 60);

      const seconds = Math.round(user.finishTimeSum % 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours} godzin, ${minutes} minut, ${seconds} sekund`;
    }
    return "-";
  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{user.nick}</td>
        <td>{user.email}</td>
        <td>{user.createdAt}</td>
        <td>{user.collectedDropCount}</td>
        <td>{user.numberOfPointsVisited}</td>
        <td>{user.lastActivityDate || "-"}</td>
        <td>
          <span>{getFinishTimeSumString()}</span>
        </td>
        <td onClick={handleChangeStatus}>{getStatusButtonView()}</td>
        <td>
          <div
            className={`collapse-icon ${isCollapsed ? " is-collapsed" : ""}`}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Icon iconName={IconTypes.ArrowSVG} />
          </div>
        </td>
      </tr>
      <tr className={`collapse-wrapper ${isCollapsed ? " is-collapsed" : ""} `}>
        <td colSpan={10}>
          <UserCardHistory userHistory={user.userHistory} />
        </td>
      </tr>
    </>
  );
};

export default UserCard;
