import React from "react";
import { useEffect, useState } from "react";

import HomeSVG from "../../assets/homeIcon.svg";
import UserSVG from "../../assets/userIcon.svg";
import DropSVG from "../../assets/drop.svg";
import DropGreySVG from "../../assets/drop-grey.svg";
import LineSVG from "../../assets/line.svg";
import LineBigSVG from "../../assets/line-big.svg";
import CloseSVG from "../../assets/close.svg";
import StarSVG from "../../assets/star.svg";
import StarGreySVG from "../../assets/star-grey.svg";
import CardBlueSVG from "../../assets/card-blue.svg";
import CardWhiteSVG from "../../assets/card-white.svg";
import BookmarkBlueSVG from "../../assets/bookmark-blue.svg";
import BookmarkWhiteSVG from "../../assets/bookmark-white.svg";
import DownloadSVG from "../../assets/download.svg";
import ImageSVG from "../../assets/image.svg";
import AddCoordinatesSVG from "../../assets/add-coordinates.svg";
import AddPhotoSVG from "../../assets/add-photo.svg";
import ArrowSVG from "../../assets/arrow.svg";
import FilterSVG from "../../assets/filter.svg";
import SearchSVG from "../../assets/search.svg";
import OkDropSVG from "../../assets/ok-drop.svg";

type IconProps = {
  iconName: IconTypes;
  size?: number;
};

export enum IconTypes {
  HomeSVG,
  UserSVG,
  LineSVG,
  LineBigSVG,
  CloseSVG,
  StarSVG,
  StarGreySVG,
  DropSVG,
  DropGreySVG,
  CardBlueSVG,
  CardWhiteSVG,
  BookmarkBlueSVG,
  BookmarkWhiteSVG,
  DownloadSVG,
  ImageSVG,
  AddCoordinatesSVG,
  AddPhotoSVG,
  ArrowSVG,
  FilterSVG,
  SearchSVG,
  OkDropSVG,
}

const Icon = ({ iconName, size }: IconProps) => {
  const [iconWidth, setIconWidth] = useState<number | undefined>(undefined);
  const [iconHeight, setIconHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (size) {
      setIconWidth(size);
      setIconHeight(size);
    }
  }, [size]);

  const getIcon = (): string | undefined => {
    switch (iconName) {
      case IconTypes.HomeSVG:
        return HomeSVG;
      case IconTypes.UserSVG:
        return UserSVG;
      case IconTypes.DropSVG:
        return DropSVG;
      case IconTypes.LineSVG:
        return LineSVG;
      case IconTypes.DropGreySVG:
        return DropGreySVG;
      case IconTypes.LineBigSVG:
        return LineBigSVG;
      case IconTypes.CloseSVG:
        return CloseSVG;
      case IconTypes.StarSVG:
        return StarSVG;
      case IconTypes.StarGreySVG:
        return StarGreySVG;
      case IconTypes.CardBlueSVG:
        return CardBlueSVG;
      case IconTypes.CardWhiteSVG:
        return CardWhiteSVG;
      case IconTypes.BookmarkBlueSVG:
        return BookmarkBlueSVG;
      case IconTypes.BookmarkWhiteSVG:
        return BookmarkWhiteSVG;
      case IconTypes.DownloadSVG:
        return DownloadSVG;
      case IconTypes.ImageSVG:
        return ImageSVG;
      case IconTypes.AddCoordinatesSVG:
        return AddCoordinatesSVG;
      case IconTypes.AddPhotoSVG:
        return AddPhotoSVG;
      case IconTypes.ArrowSVG:
        return ArrowSVG;
      case IconTypes.FilterSVG:
        return FilterSVG;
      case IconTypes.SearchSVG:
        return SearchSVG;
      case IconTypes.OkDropSVG:
        return OkDropSVG;
      default:
        return undefined;
    }
  };

  return <img src={getIcon()} alt={`icon-${iconName}`} width={iconWidth} height={iconHeight} />;
};

export default Icon;
