import React from "react";
import { Modal } from "react-bootstrap";
import AppButton from "./AppButton";

type ImageModalProp = {
  show: boolean;
  onClose: any;
  imageSrc: string;
};

const ImageModal = ({ show, onClose, imageSrc }: ImageModalProp) => {
  return (
    <Modal className="image-modal-wrapper" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Podgląd zdjęcia lokalizacji</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={imageSrc} width="100%" height="100%" />
      </Modal.Body>
      <Modal.Footer>
        <AppButton customClass="cancel-button" title="Zamknij" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ImageModal);
