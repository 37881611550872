import React from "react";
import Icon, { IconTypes } from "../../common/components/Icon";

type NavigationTypes = {
  navigationData: NavigationData;
  showLine?: boolean;
};

type NavigationData = {
  iconName: IconTypes;
  moduleName: string;
  size?: number;
};

const Navigation = ({ navigationData, showLine }: NavigationTypes) => {
  return (
    <div className={`navigation-wrapper ${showLine ? "show-line" : ""}`}>
      <Icon iconName={IconTypes.LineSVG} />
      <div className="menu-route-wrapper">
        <Icon iconName={navigationData.iconName} size={60} />
        <span className="is-active">{navigationData.moduleName}</span>
      </div>
    </div>
  );
};

export default Navigation;
