import React from "react";
import { Modal } from "react-bootstrap";
import AppButton from "./AppButton";
import QRCode from "react-qr-code";

type QrCodeModalProp = {
  show: boolean;
  onClose: any;
  qrCodeValue: number;
  qrCodeSize: number;
};

const QrCodeModal = ({ show, onClose, qrCodeValue, qrCodeSize }: QrCodeModalProp) => {
  return (
    <Modal className="qrcode-modal-wrapper" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Podgląd kodu QR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-code-wrapper">
          <QRCode
            size={qrCodeSize}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={qrCodeValue.toString()}
            viewBox={`0 0 ${qrCodeSize} ${qrCodeSize}`}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton customClass="cancel-button" title="Zamknij" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(QrCodeModal);
