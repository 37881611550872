import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { gameApi } from "../features/game/gameApi";
import { localizationApi } from "../features/localization/localizationApi";
import { loginApi } from "../features/login/loginApi";
import { userApi } from "../features/user/userApi";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    [localizationApi.reducerPath]: localizationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(gameApi.middleware)
      .concat(localizationApi.middleware)
      .concat(userApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export enum AppStorageTypes {
  CURRENT_GAME = "CURRENT_GAME",
  GAME_ID = "GAME_ID",
  TOKEN = "TOKEN",
}
