import React from "react";

const FinishedGame = () => {
  return (
    <div className="finish-game-wrapper">
      <p>Gra zakończona</p>
      <p>Gra została zakończona</p>
    </div>
  );
};

export default FinishedGame;
