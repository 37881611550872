import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AppButton from "../../common/components/AppButton";

type LocalizationModalProp = {
  show: boolean;
  onChange: any;
  onClose: any;
  currentPosition?: string;
};

const containerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  // pabianice
  lat: 51.6571163,
  lng: 19.3536673,
};

const LocalizationModal = ({ currentPosition, show, onChange, onClose }: LocalizationModalProp) => {
  const [selectedPosition, setSelectedPosition] = useState<number[] | null>();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB9kqUtjJrtGEFQWOeWYsGOZ8LPfEna0nQ",
  });

  useEffect(() => {
    if (currentPosition) {
      setSelectedPosition(currentPosition.split(",").map(Number));
    }
  }, [currentPosition]);

  const handleChange = (): void => {
    setSelectedPosition(null);
    onChange(selectedPosition);
  };

  const handleSelectedPosition = (e: any): void => {
    setSelectedPosition([e.latLng.lat(), e.latLng.lng()]);
  };

  const handleOnClose = (): void => {
    setSelectedPosition(null);
    onClose();
  };

  const getSelectedPositionView = (): React.ReactNode => {
    if (isLoaded && selectedPosition && selectedPosition.length) {
      return (
        <Marker
          position={{
            lat: selectedPosition[0],
            lng: selectedPosition[1],
          }}
        />
      );
    }
    return null;
  };

  return (
    <Modal className="localization-modal-wrapper" show={show} onHide={handleOnClose}>
      <Modal.Header closeButton>
        <Modal.Title>Wybierz lokalizację punktu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedPosition && selectedPosition.length && (
          <div className="modal-coordinates-wrapper">
            <p>Wybrana lokalizacja</p>
            <span>{selectedPosition.join(", ")}</span>
          </div>
        )}
        <p>
          Aby wybrać lokalizacje, kliknij w wybrany punkt na mapie. Aby mapę oddalić bądź przybliżyć, naciśnij{" "}
          <strong>CTRL</strong> a nastepnie użyj <strong>scrolla</strong> myszki.
        </p>
        {isLoaded && (
          <GoogleMap
            onClick={handleSelectedPosition}
            options={{ disableDefaultUI: true }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            {getSelectedPositionView()}
            <Marker position={{ lat: 51.6571163, lng: 19.3536673 }} />
          </GoogleMap>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AppButton title="Dodaj punkt" onClick={handleChange} />
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(LocalizationModal);
