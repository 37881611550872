import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Icon, { IconTypes } from "../../common/components/Icon";
import { PhotoInterface } from "../../interfaces/photo";
import { includes as _includes } from "lodash";

type MediaProp = {
  onChange: any;
  value: string | ArrayBuffer | null;
  photoNumber: number;
  name: string;
  error?: string | null;
};

export enum MediaParamTypes {
  MEDIA_FORMAT = "jpg/jpeg",
  MEDIA_WIDTH = 500,
  MEDIA_HEIGHT = 300,
  MEDIA_WEIGHT = 200,
}

enum MediaErrorTypes {
  FORMAT_ERROR = "Format zdjęcia jest inny niż wymagany",
  SIZE_ERROR = "Wymagany rozmiar zdjęcia to 500x300",
  WEIGHT_ERROR = "Waga zdjęcia jest zbyt duża",
  IS_REQUIRED = "Zdjęcie jest wymagane",
}

const imageAllowedFormats: string[] = ["image/jpeg", "image/jpg"];

const Media = ({ onChange, value, photoNumber, name, error }: MediaProp) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [preview, setPreview] = useState<any>(null);

  useEffect(() => {
    if (error) {
      setErrorMessage(MediaErrorTypes.IS_REQUIRED);
    }
  }, [error]);

  useEffect(() => {
    if (value) {
      setPreview(value);
    } else {
      setPreview(null);
    }
  }, [value]);

  const handleSetError = (errorType: MediaErrorTypes): void => {
    setErrorMessage(errorType);

    setTimeout(() => {
      setErrorMessage("");
    }, 2500);
  };

  const convertToBase64 = (file: Blob): void => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result && reader.result) {
        const newPhoto: PhotoInterface = {
          image: reader.result,
        };

        const newImg: HTMLImageElement = new Image();
        newImg.src = reader.result.toString();

        newImg.onload = () => {
          const imageWidth: number = newImg.width;
          const imageHeight: number = newImg.height;

          if (imageWidth !== MediaParamTypes.MEDIA_WIDTH || imageHeight !== MediaParamTypes.MEDIA_HEIGHT) {
            handleSetError(MediaErrorTypes.SIZE_ERROR);
            return;
          }

          onChange(newPhoto);
        };
      }
    };
  };

  const handleChooseFile = (event: any): void => {
    setErrorMessage("");
    const target = event.target as HTMLInputElement;

    if (target) {
      const files = target.files as FileList;

      if (files && files.length) {
        const file: File = files[0];
        const fileType: string = file.type;
        const fileSize: number = file.size / 1024;

        if (!_includes(imageAllowedFormats, fileType)) {
          handleSetError(MediaErrorTypes.SIZE_ERROR);
          return;
        }

        if (fileSize > MediaParamTypes.MEDIA_WEIGHT) {
          handleSetError(MediaErrorTypes.WEIGHT_ERROR);
          return;
        }

        convertToBase64(file);
      }
    }
  };

  const handleRemoveImage = (): void => {
    setPreview(null);
    onChange("");
  };

  const getMediaView = () => {
    if (preview) {
      return (
        <div className="preview-wrapper">
          <img className="image-preview" src={preview} />
          <div className="delete-icon" onClick={handleRemoveImage}>
            <Icon iconName={IconTypes.CloseSVG} />
          </div>
        </div>
      );
    } else {
      const fieldId = `${name}-file-upload-${photoNumber}`;

      return (
        <Form.Label htmlFor={fieldId}>
          <span>{`Wybierz zdjęcie numer ${photoNumber}`}</span>
          <Icon iconName={IconTypes.AddPhotoSVG} />
          <input type="file" accept=".jpg, .png|image/*" id={fieldId} name="Upload image" onChange={handleChooseFile} />
          {errorMessage && <p className="error-text">{errorMessage}</p>}
        </Form.Label>
      );
    }
  };

  return <div className="single-media-wrapper">{getMediaView()}</div>;
};

export default Media;
