import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { UserHistoryInterface } from "../../interfaces/userHistory";

type UserProp = {
  userHistory: UserHistoryInterface[]; //@TODO USER INTERFACE HERE
};

const UserCardHistory = ({ userHistory }: UserProp) => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const getLocationHistoryView = (): React.ReactNode => {
    if (!userHistory || !userHistory.length) {
      return (
        <tr>
          <td colSpan={3}>Brak wyników</td>
        </tr>
      );
    }

    return userHistory.map((localizationHistory: UserHistoryInterface, index: number) => {
      //@TODO ADD INTERFACE HERE
      return (
        <tr key={`user-single-history-${index}`}>
          <td onClick={() => navigate(`/game/${gameId}/localization/${localizationHistory.localizationId}`)}>
            {localizationHistory.localizationId}
          </td>
          <td>{localizationHistory.createdAt}</td>
          <td>{localizationHistory.isSolved ? "TAK" : "NIE"}</td>
        </tr>
      );
    });
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Identyfikator lokalizacji</th>
          <th>Data odwiedzenia</th>
          <th>Czy rozwiązana?</th>
        </tr>
      </thead>
      <tbody>{getLocationHistoryView()}</tbody>
    </Table>
  );
};

export default UserCardHistory;
