import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderSVG from "../../assets/header.svg";
import LogoSVG from "../../assets/logo.svg";
import Menu from "../../common/components/Menu";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="header-wrapper">
      <img src={HeaderSVG} width="100%" alt="logo" />
      <img onClick={() => navigate("/home")} src={LogoSVG} className="header-logo" alt="logo" />
      <Menu />
    </div>
  );
};

export default Header;
