import React from "react";
import { Form } from "react-bootstrap";
import Icon, { IconTypes } from "./Icon";

type AppInputProps = {
  name: string;
  register: any;
  label?: string;
  placeholder?: string;
  value?: string | number;
  customClass?: string;
  spacing?: number;
  type?: string;
  error?: string | null;
  disabled?: boolean;
};

const AppInput = ({
  name,
  register,
  label,
  placeholder,
  value,
  customClass,
  spacing = 3,
  type = "text",
  error,
  disabled,
}: AppInputProps) => {
  console.log(disabled);
  const { onChange } = register(name);

  const emptyValueByType = (): string | number | undefined => {
    if (type === "text") {
      return "";
    }
    if (type === "number") {
      return 0;
    }
  };

  return (
    <Form.Group className={`app-input mb-${spacing} ${error ? "has-error" : ""}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="input-wrapper">
        <Form.Control
          autoComplete="off"
          disabled={disabled}
          {...register(name)}
          type={type}
          value={value}
          placeholder={placeholder}
        />
        {error && <p className="error-text">{error}</p>}
        {!!value && (
          <div className="clear-input-icon-wrapper" onClick={() => onChange(name, emptyValueByType())}>
            <Icon iconName={IconTypes.CloseSVG} />
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default AppInput;
