import React, { useEffect, useState } from "react";
import { get as _get, includes as _includes } from "lodash";
import { GameInterface, StatusEnum } from "../../interfaces/game";
import NewGame from "./NewGame";
import StartGame from "./StartGame";
import FinishGame from "./FinishGame";
import { AppStorageTypes } from "../../app/store";
import FinishedGame from "./FinishedGame";

const Home = () => {
  const [currentGame, setCurrentGame] = useState<GameInterface>();

  useEffect(() => {
    const gameData: string | null = localStorage.getItem(AppStorageTypes.CURRENT_GAME);
    if (gameData) {
      const currentGame: GameInterface = JSON.parse(gameData);
      setCurrentGame(currentGame);
    }
  }, []);

  const getCurrentView = (): React.ReactNode => {
    if (!currentGame) {
      return <NewGame />;
    } else if (currentGame && currentGame.status === StatusEnum.NEW) {
      return <StartGame handleSetCurrentGame={setCurrentGame} currentGame={currentGame} />;
    } else if (currentGame && currentGame.status === StatusEnum.STARTED) {
      return <FinishGame handleSetCurrentGame={setCurrentGame} currentGame={currentGame} />;
    } else {
      return <FinishedGame />;
    }
  };

  return <div className="home-wrapper">{getCurrentView()}</div>;
};

export default Home;
