import React, { useEffect, useState } from "react";
import { Control, FieldErrors, useFieldArray, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";
import AppButton from "../../common/components/AppButton";
import AppTextarea from "../../common/components/AppTextarea";
import Icon, { IconTypes } from "../../common/components/Icon";
import { RiddleTypeEnum } from "../../interfaces/riddle";
import { RiddleQuestionInterface } from "../../interfaces/riddleQuestion";
import RiddleAnswers from "./RiddleAnswers";
import { get as _get } from "lodash";
import Media from "../game/Media";
import { UseFormGetValues } from "react-hook-form/dist/types/form";
import { PhotoInterface } from "../../interfaces/photo";
import { Col, Row } from "react-bootstrap";

type RiddleQuestionsProp = {
  riddleIndex: number;
  control: Control<any>;
  errors: FieldErrors | string | null;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
};

const RiddleQuestions = ({ control, errors, register, riddleIndex, setValue, getValues }: RiddleQuestionsProp) => {
  const [lastRiddleType, setLastRiddleType] = useState<RiddleTypeEnum>();
  const { fields, append, remove } = useFieldArray({ name: `riddles[${riddleIndex}].riddleQuestions`, control });

  const riddleType = useWatch({
    control,
    name: `riddles[${riddleIndex}].riddleType`,
  });

  const riddleQuestions = useWatch({
    control,
    name: `riddles[${riddleIndex}].riddleQuestions`,
  });

  if (lastRiddleType && lastRiddleType !== riddleType) {
    setLastRiddleType(riddleType);
    setValue(`riddles[${riddleIndex}].riddleQuestions`, []);
  }

  if (riddleType !== lastRiddleType) {
    setLastRiddleType(riddleType);
  }

  const isShownAddButton = (): boolean => {
    if (riddleType !== RiddleTypeEnum.QUIZ && riddleQuestions.length > 0) {
      return false;
    }
    return true;
  };

  const handleAddRiddleQuestion = (): void => {
    const newRiddleQuestion: RiddleQuestionInterface = {
      questionName: "",
      questionAnswers: [],
    };

    append(newRiddleQuestion);
  };

  const handleRemoveRiddleQuestion = (riddleQuestionIndex: number): void => {
    remove(riddleQuestionIndex);
  };

  const handleSetQuestionNameImage = (questionIndex: number, photoValue: PhotoInterface): void => {
    setValue(
      `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionName`,
      photoValue && photoValue.image ? photoValue.image : "",
    );
  };

  const getRiddleQuestionNameView = (questionIndex: number): React.ReactNode => {
    if (riddleType === RiddleTypeEnum.REBUS) {
      return (
        <Row className="media-wrapper">
          <Col>
            <Media
              photoNumber={1}
              name="riddlePhoto"
              value={getValues(`riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionName`)}
              onChange={(photoValue: PhotoInterface) => handleSetQuestionNameImage(questionIndex, photoValue)}
              error={_get(
                errors,
                `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionName.message`,
                null,
              )}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <AppTextarea
          register={register}
          label="Treść zagadnienia"
          placeholder="Uzupełnij treść zagadnienia"
          name={`riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionName`}
          error={_get(errors, `riddles[${riddleIndex}].riddleQuestions[${questionIndex}].questionName.message`, null)}
        />
      );
    }
  };

  return (
    <div className="riddle-questions-wrapper">
      {fields.map((item, questionIndex) => {
        return (
          <div key={item.id} className="single-riddle-question-wrapper">
            {getRiddleQuestionNameView(questionIndex)}
            <RiddleAnswers questionIndex={questionIndex} riddleIndex={riddleIndex} {...{ control, errors, register }} />
            <div className="remove-button-wrapper" onClick={() => handleRemoveRiddleQuestion(questionIndex)}>
              <Icon iconName={IconTypes.CloseSVG} />
            </div>
          </div>
        );
      })}
      {isShownAddButton() && (
        <div onClick={handleAddRiddleQuestion}>
          <AppButton title="Dodaj kolejne zagadnienie" />
        </div>
      )}
      {_get(errors, `riddles[${riddleIndex}].riddleQuestions.message`, null) && (
        <p className="error-text">{_get(errors, `riddles[${riddleIndex}].riddleQuestions.message`, null)}</p>
      )}
    </div>
  );
};

export default RiddleQuestions;
