import * as yup from "yup";
import { GameInterface } from "../../interfaces/game";

export const gameValidationSchema = yup.object({
  name: yup.string().required("Pole jest wymagane"),
  dropCountLimit: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .required("Pole jest wymagane"),
  dropCountRequired: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .required("Pole jest wymagane"),
  hourCountToCollectDrops: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .required("Pole jest wymagane"),
});

export const gameInitialValues: GameInterface = {
  name: "",
  dropCountLimit: null,
  dropCountRequired: null,
  hourCountToCollectDrops: null,
};
