import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../common/components/AppButton";
import AppInput from "../../common/components/AppInput";
import AppRadio, { AppRadioOption } from "../../common/components/AppRadio";
import AppTextarea from "../../common/components/AppTextarea";
import { IconTypes } from "../../common/components/Icon";
import LocalizationModal from "./LocalizationModal";
import { localizationInitialValues, localizationValidationSchema } from "./localizationValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { get as _get } from "lodash";
import {
  useAddLocalizationMutation,
  useEditLocalizationMutation,
  useLazyGetLocalizationQuery,
} from "./localizationApi";
import Riddles from "../riddle/Riddles";
import { PhotoInterface } from "../../../../shared/photo";
import { AppStorageTypes } from "../../app/store";
import { GameInterface, StatusEnum } from "../../interfaces/game";

enum LocalizationTypeEnum {
  QR_CODE = "QR_CODE",
  IN_NEAR = "IN_NEAR",
}

const localizationOption: AppRadioOption[] = [
  {
    name: "W pobliżu",
    value: LocalizationTypeEnum.IN_NEAR,
  },
  {
    name: "Kod QR",
    value: LocalizationTypeEnum.QR_CODE,
  },
];

const Localization = () => {
  const { gameId } = useParams();
  const { localizationId } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentGame, setCurrentGame] = useState<GameInterface>();

  const navigate = useNavigate();
  //@TODO HANDLE ERROR
  const [getLocationById, { data: locationData, error: getLocationError }] = useLazyGetLocalizationQuery();
  const [addLocation, { data: addLocationData, error: addLocationError, isLoading: addLocationIsLoading }] =
    useAddLocalizationMutation();
  const [editLoaction, { data: editLocationData, error: editLocationError, isLoading: editLocationIsLoading }] =
    useEditLocalizationMutation();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: localizationInitialValues,
    resolver: yupResolver(localizationValidationSchema),
  });

  const photos = watch("photos");
  const localizationType = watch("localizationType");
  const coordinates = watch("coordinates");

  if (localizationType === LocalizationTypeEnum.QR_CODE) {
    setValue("distance", null);
  }

  useEffect(() => {
    const gameData: string | null = localStorage.getItem(AppStorageTypes.CURRENT_GAME);
    if (gameData) {
      const currentGame: GameInterface = JSON.parse(gameData);
      setCurrentGame(currentGame);
    }
  }, []);

  // IF LOCATION ID IN ROUTE, GET THIS LOCATION
  useEffect(() => {
    if (localizationId) {
      getLocationById(localizationId);
    }
  }, [localizationId]);

  // SET VALUES FROM API BY LOCATION ID
  useEffect(() => {
    if (locationData && locationData.document) {
      reset(locationData.document);
    }
  }, [locationData]);

  // IF ADD/EDIT IS SUCCESS, GO TO LOCATIONS
  useEffect(() => {
    if (addLocationData && addLocationData.documentId) {
      navigate(`/game/${gameId}/localizations`);
    }

    if (editLocationData && editLocationData.documentId) {
      navigate(`/game/${gameId}/localizations`);
    }
  }, [addLocationData, editLocationData]);

  // SET LOADING
  useEffect(() => {
    setIsLoading(addLocationIsLoading || editLocationIsLoading);
  }, [addLocationIsLoading, editLocationIsLoading]);

  useEffect(() => {
    if (getLocationError) {
      navigate(`/game/${gameId}/localizations`);
    }
  }, [getLocationError]);

  const handleChangeCoordinates = (coordinates: string[]): void => {
    setValue("coordinates", coordinates.join(", "));
    clearErrors("coordinates");
    setShowModal(false);
  };

  const setPhotoValue = (photoValue: PhotoInterface): void => {
    setValue("photos", [...photos, photoValue]);
  };

  const getPhotoValueByIndex = (photoIndex: number): string | ArrayBuffer | null => {
    if (photos && photos.length && photos[photoIndex]) {
      return photos[photoIndex].image;
    }

    return null;
  };

  // HANDLE LOCATION FORM SUBMIT FOR ADD/EDIT
  const onSubmit = handleSubmit((locationData: any): void => {
    if (gameId) {
      locationData.gameId = parseInt(gameId);
    }

    if (localizationId) {
      editLoaction({ localizationId: localizationId, data: locationData });
    } else {
      addLocation(locationData);
    }
  });

  const getSaveButtonView = () => {
    if (!currentGame || (currentGame && currentGame.status === StatusEnum.NEW)) {
      return (
        <div className="button-wrapper">
          <AppButton
            type="submit"
            isLoading={isLoading}
            title={`${!localizationId ? "Dodaj nową lokalizację" : "Zapisz lokalizację"}`}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="localization-form-wrapper">
      <Form className="game-form" onSubmit={onSubmit}>
        <AppInput
          register={register}
          placeholder="Podaj nazwę lokalizacji"
          label={"Nazwa lokalizacji"}
          name="title"
          error={_get(errors, "title.message", null)}
        />
        <AppTextarea
          placeholder="Uzupełnij opis lokaliacji"
          register={register}
          name="description"
          label={"Opis lokalizacji"}
          error={_get(errors, "description.message", null)}
        />
        <AppRadio register={register} options={localizationOption} name="localizationType" label="Typ lokalizacji" />
        {localizationType === LocalizationTypeEnum.IN_NEAR && (
          <AppInput
            register={register}
            placeholder="Uzupełnij dystans"
            type="number"
            label={"Dystans (w metrach)"}
            name="distance"
            error={_get(errors, "distance.message", null)}
          />
        )}
        <div className="coordinates-wrapper">
          <Form.Group className="coordinates-wrapper mb-3">
            <Form.Label>Lokalizacja punktu</Form.Label>
            {coordinates && <p className="localization-coordinates">{coordinates}</p>}
            <AppButton onClick={() => setShowModal(true)} icon={IconTypes.AddCoordinatesSVG} />
            {_get(errors, "coordinates.message", null) && <p className="error-text">Pole jest wymagane</p>}
          </Form.Group>
        </div>
        {/* <Row className="media-wrapper">
          <Form.Label>Dodaj zdjęcia do lokalizacji (max 3)</Form.Label>
          <div className="media-recomendations-wrapper">
            <span className="field-description">
              Zdjęcie musi być w formacie <b>{MediaParamTypes.MEDIA_FORMAT}</b>
            </span>
            <span className="field-description">
              Zdjęcie musi być w rozmiarze{" "}
              <b>{`${MediaParamTypes.MEDIA_WEIGHT} x ${MediaParamTypes.MEDIA_HEIGHT} px`}</b>
            </span>
            <span className="field-description">
              Zdjęcie może mieć mieć maksymalny rozmiar <b>{`${MediaParamTypes.MEDIA_WEIGHT} kb`}</b>
            </span>
          </div>
          <Col>
            <Media photoNumber={1} name="locationPhoto" value={getPhotoValueByIndex(0)} onChange={setPhotoValue} />
          </Col>
          <Col>
            <Media photoNumber={2} name="locationPhoto" value={getPhotoValueByIndex(1)} onChange={setPhotoValue} />
          </Col>
          <Col>
            <Media photoNumber={3} name="locationPhoto" value={getPhotoValueByIndex(2)} onChange={setPhotoValue} />
          </Col>
        </Row> */}
        <Riddles {...{ control, register, errors, setValue, getValues }} />
        {getSaveButtonView()}
      </Form>
      <LocalizationModal
        show={showModal}
        currentPosition={coordinates}
        onChange={handleChangeCoordinates}
        onClose={setShowModal}
      />
    </div>
  );
};

export default Localization;
