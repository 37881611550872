import React from "react";
import { useNavigate } from "react-router-dom";
import Icon, { IconTypes } from "../../common/components/Icon";

const NewGame = () => {
  const navigate = useNavigate();

  const handleCreateGame = (): void => {
    navigate("/game");
  };

  return (
    <div className="new-game-wrapper">
      <p>Witamy w module zarządzania aplikacją mobilną</p>
      <p>Aktualnie żadna gra nie jest aktywna. Czy chcesz utworzyć nową grę?</p>
      <div onClick={handleCreateGame} className="create-new-game-button">
        <Icon iconName={IconTypes.DropSVG} size={56} />
        <span>Utwórz nową grę</span>
      </div>
    </div>
  );
};

export default NewGame;
