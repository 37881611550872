import React from "react";

const NoFound = () => {
  return (
    <div className="no-found-wrapper">
      <p>Strona nie istnieje. Zawróć..</p>
    </div>
  );
};

export default NoFound;
