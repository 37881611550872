import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../app/apiConfig";
import { ApiResponse } from "../../interfaces/application";
import { UserInterface, UserStatusInterface } from "../../interfaces/user";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.query<ApiResponse<UserInterface>, void>({
      query: () => ({
        url: `user/user`,
      }),
      providesTags: ["Users"],
    }),
    updateUserStatus: build.mutation<ApiResponse<UserStatusInterface>, { userId: number; data: UserStatusInterface }>({
      query: ({ userId, data }) => ({
        url: `user/userStatus/${userId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const { useGetUsersQuery, useUpdateUserStatusMutation } = userApi;
