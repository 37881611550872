import React, { useState } from "react";
import ConfirmModal from "../../common/components/ConfirmModal";
import Icon, { IconTypes } from "../../common/components/Icon";
import { GameInterface, StatusEnum } from "../../interfaces/game";
import { useUpdateGameStatusMutation } from "../game/gameApi";

type FinishGameProp = {
  currentGame: GameInterface;
  handleSetCurrentGame: (currentGame: GameInterface) => void;
};

const ModalText = () => {
  return (
    <>
      <p>Czy na pewno chcesz zakończyć grę?</p>
      <p>Upewnij się, że wszyscy graczę ukończyli grę, w innym przypadku uniemożliwisz im jej dokończenia.</p>
    </>
  );
};

const FinishGame = ({ currentGame, handleSetCurrentGame }: FinishGameProp) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateGameStatus] = useUpdateGameStatusMutation();

  const handleFinishGame = (): void => {
    if (currentGame && currentGame.id) {
      updateGameStatus({ gameId: currentGame.id, data: { status: StatusEnum.FINISHED } });
      const gameTmp = { ...currentGame };
      gameTmp.status = StatusEnum.FINISHED;
      handleSetCurrentGame(gameTmp);
      setShowModal(false);
    }
  };

  return (
    <div className="finish-game-wrapper">
      <p>Gra rozpoczęta!</p>
      <p>Aby zarządzać grą, sprawdzać punkty i dane użytkowników przejdź do odpowiednich zakładek.</p>
      <p>
        Możesz zakończyć aktualnie trwającą grę. Gdy to zrobisz, realizacja zadań nie będzie dostępna już dla
        użytkowników, ale będziesz mieć w dalszym ciągu dostęp do wyników gry.{" "}
      </p>
      <div onClick={() => setShowModal(true)} className="finish-game-button">
        <Icon iconName={IconTypes.DropSVG} size={56} />
        <span>Zakończ grę</span>
      </div>

      <ConfirmModal
        show={showModal}
        onChange={handleFinishGame}
        cancelTitle="Jeszcze sie upewnię"
        confirmTitle="Tak, chcę!"
        modalText={<ModalText />}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default FinishGame;
