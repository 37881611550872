import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../app/apiConfig";
import { ApiResponse } from "../../interfaces/application";
import { LocalizationInterface } from "../../interfaces/localization";

export const localizationApi = createApi({
  reducerPath: "localizationApi",
  baseQuery: baseQuery,
  tagTypes: ["Localizations", "Localization"],
  endpoints: (build) => ({
    getLocaliaztions: build.query<ApiResponse<LocalizationInterface>, string>({
      query: (gameId) => ({
        url: `localization/game/${gameId}`,
      }),
      providesTags: ["Localizations"],
    }),
    getLocalization: build.query<ApiResponse<LocalizationInterface>, string>({
      query: (localizationId) => ({
        url: `localization/${localizationId}`,
      }),
      providesTags: ["Localization"],
    }),
    addLocalization: build.mutation<ApiResponse<LocalizationInterface>, LocalizationInterface>({
      query: (data) => ({
        url: `localization`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Localization"],
    }),
    editLocalization: build.mutation<
      ApiResponse<LocalizationInterface>,
      { localizationId: string; data: LocalizationInterface }
    >({
      query: ({ localizationId, data }) => ({
        url: `localization/${localizationId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Localization"],
    }),
    deleteLocation: build.mutation<ApiResponse<LocalizationInterface>, number>({
      query: (localizationId) => ({
        url: `localization/${localizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Localizations"],
    }),
  }),
});

export const {
  useLazyGetLocalizationQuery,
  useLazyGetLocaliaztionsQuery,
  useAddLocalizationMutation,
  useEditLocalizationMutation,
  useDeleteLocationMutation,
} = localizationApi;
