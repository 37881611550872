import React from "react";
import { Form } from "react-bootstrap";
import { Control, useFieldArray, UseFormRegister, UseFormSetValue } from "react-hook-form";
import AppButton from "../../common/components/AppButton";
import AppRadio, { AppRadioOption } from "../../common/components/AppRadio";
import Icon, { IconTypes } from "../../common/components/Icon";
import { RiddleInterface, RiddleTypeEnum } from "../../interfaces/riddle";
import RiddleQuestions from "./RiddleQuestions";
import { get as _get } from "lodash";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormGetValues } from "react-hook-form/dist/types/form";

type RiddlesProp = {
  control: Control<any>;
  errors: FieldErrors | string | null;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
};

const riddleTypeOption: AppRadioOption[] = [
  {
    name: "Łamigówka",
    value: RiddleTypeEnum.REBUS,
  },
  {
    name: "Quiz",
    value: RiddleTypeEnum.QUIZ,
  },
  {
    name: "Zagadka",
    value: RiddleTypeEnum.PUZZLE,
  },
];

const Riddles = ({ control, register, errors, setValue, getValues }: RiddlesProp) => {
  const { fields, append, remove } = useFieldArray({ name: "riddles", control });

  const handleAddRiddle = (): void => {
    const newRiddle: RiddleInterface = {
      riddleType: RiddleTypeEnum.REBUS,
      riddleQuestions: [],
    };

    append(newRiddle);
  };

  const handleRemoveRiddle = (riddleIndex: number): void => {
    remove(riddleIndex);
  };

  return (
    <div className="riddles-wrapper">
      <Form.Label>Zagadnienia do rozwiązania</Form.Label>
      {fields.map((item, riddleIndex) => {
        return (
          <div key={item.id} className="single-riddle-wrapper">
            <AppRadio
              register={register}
              label="Rodzaj zagadnienia"
              name={`riddles[${riddleIndex}].riddleType`}
              options={riddleTypeOption}
              error={_get(errors, `riddles[${riddleIndex}].riddleType.message`, null)}
            />
            <RiddleQuestions riddleIndex={riddleIndex} {...{ control, register, errors, setValue, getValues }} />
            <div className="remove-button-wrapper" onClick={() => handleRemoveRiddle(riddleIndex)}>
              <Icon iconName={IconTypes.CloseSVG} />
            </div>
          </div>
        );
      })}
      <div onClick={handleAddRiddle}>
        <AppButton title="Dodaj zagadkę" />
      </div>
      {_get(errors, "riddles.message", null) && <p className="error-text">{_get(errors, "riddles.message", null)}</p>}
    </div>
  );
};

export default Riddles;
