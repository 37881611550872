import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Root from "./Root";

import "@fontsource/roboto";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "./styles/header.scss";
import "./styles/footer.scss";
import "./styles/navigation.scss";
import "./styles/login.scss";
import "./styles/game.scss";
import "./styles/localization.scss";
import "./styles/modal.scss";
import "./styles/media.scss";
import "./styles/riddle.scss";
import "./styles/user.scss";
import "./styles/filter.scss";
import "./styles/home.scss";


const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
);
