import React from "react";
import { Button } from "react-bootstrap";
import Icon, { IconTypes } from "./Icon";

type AppButtonProps = {
  title?: string;
  customClass?: string;
  icon?: IconTypes;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
};

const AppButton = ({ title, customClass, icon, onClick, type = "button", isLoading }: AppButtonProps) => {
  return (
    <Button onClick={onClick} type={type} className={`${customClass ? customClass : ""}`} variant="primary">
      {isLoading ? "..." : title}
      {icon && <Icon iconName={icon} />}
    </Button>
  );
};

export default AppButton;
