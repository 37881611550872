import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppStorageTypes } from "../../app/store";
import HomeIcon from "../../assets/homeIcon.svg";
import UserIcon from "../../assets/userIcon.svg";

const Menu = () => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogout = (): void => {
    localStorage.removeItem(AppStorageTypes.TOKEN);
    setIsLogged(false);
    navigate("/login");
  };

  useEffect(() => {
    const token = localStorage.getItem(AppStorageTypes.TOKEN);
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  });

  return (
    <div className="menu-wrapper">
      {isLogged && (
        <ul>
          <li onClick={() => navigate("/home")}>
            <img src={HomeIcon} alt="home-icon" />
          </li>
          {/* <li className="user-info">
            <img src={UserIcon} alt="profile-icon" />
          </li> */}
          <li onClick={handleLogout}>
            <div>Wyloguj</div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Menu;
