import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutlet, useParams } from "react-router-dom";
import Icon, { IconTypes } from "../../common/components/Icon";
import Navigation from "../app/Navigation";
import { includes as _includes } from "lodash";
import { AppStorageTypes } from "../../app/store";
import { GameInterface } from "../../interfaces/game";
import { useGetGamesQuery } from "./gameApi";

const GameWrapper = () => {
  const outlet = useOutlet();
  const { gameId } = useParams();
  const [currentGameId, setCurrentGameId] = useState<number>();
  const { data, isLoading } = useGetGamesQuery();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && currentGameId && gameId) {
      if (currentGameId.toString() !== gameId) {
        navigate("/home");
      }
    }
  }, [currentGameId, gameId]);

  useEffect(() => {
    if (data && data.documents) {
      if (data.documents.length) {
        const currentGame: GameInterface = data.documents[0];
        localStorage.setItem(AppStorageTypes.CURRENT_GAME, JSON.stringify(currentGame));

        if (currentGame.id) {
          setCurrentGameId(currentGame.id);
          localStorage.setItem(AppStorageTypes.GAME_ID, currentGame.id.toString());
        }
      } else {
        localStorage.removeItem(AppStorageTypes.CURRENT_GAME);
        localStorage.removeItem(AppStorageTypes.GAME_ID);
        navigate("/home");
      }
    }
  }, [data]);

  const isActive = (routeName: string): string => {
    let isActive = false;

    switch (routeName) {
      case "user":
      case "localization":
        if (location.pathname.indexOf(routeName) >= 0) {
          isActive = true;
        }
        break;

      case "game":
        if (location.pathname === "/game" || (gameId && location.pathname === `/game/${gameId}`)) {
          isActive = true;
        }
        break;
    }

    if (isActive) {
      return "is-active";
    }

    return "";
  };

  const isDisabled = (): string => {
    if (!currentGameId) {
      return "is-disabled";
    }
    return "";
  };

  const handleNavigateTo = (route: string): void => {
    if (route === "users" && currentGameId) {
      navigate(`/game/${currentGameId}/users`);
    }

    if (route === "localizations" && currentGameId) {
      navigate(`/game/${currentGameId}/localizations`);
    }

    if (route === "game") {
      if (currentGameId) {
        navigate(`/game/${currentGameId}`);
      } else {
        navigate(`/game`);
      }
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="game-wrapper">
          <Navigation
            navigationData={{
              iconName: IconTypes.DropGreySVG,
              moduleName: "Moduł gry",
            }}
          />
          <div>
            <div className="tab-wrapper">
              <div
                className={`single-tab ${isActive("user")} ${isDisabled()}`}
                onClick={() => {
                  handleNavigateTo("users");
                }}
              >
                <Icon iconName={isActive("user") ? IconTypes.DropGreySVG : IconTypes.DropSVG} size={32} />
                <span>Użytkownicy</span>
              </div>
              <div
                className={`single-tab ${isActive("localization")}  ${isDisabled()}`}
                onClick={() => handleNavigateTo("localizations")}
              >
                <Icon iconName={isActive("localization") ? IconTypes.CardWhiteSVG : IconTypes.CardBlueSVG} size={36} />
                <span>Lokalizacje</span>
              </div>
              <div className={`single-tab ${isActive("game")}`} onClick={() => handleNavigateTo("game")}>
                <Icon iconName={isActive("game") ? IconTypes.BookmarkWhiteSVG : IconTypes.BookmarkBlueSVG} size={36} />
                <span>O Grze</span>
              </div>
            </div>
            {outlet}
          </div>
        </div>
      )}
    </>
  );
};

export default GameWrapper;
