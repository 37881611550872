import React from "react";
import { Modal } from "react-bootstrap";
import AppButton from "./AppButton";
import Icon, { IconTypes } from "./Icon";

type ConfirmModalProp = {
  show: boolean;
  onChange: any;
  onClose: any;
  cancelTitle: string;
  confirmTitle: string;
  modalText: React.ReactNode;
};

const ConfirmModal = ({ show, onChange, onClose, cancelTitle, confirmTitle, modalText }: ConfirmModalProp) => {
  const handleChange = (): void => {
    onChange(true);
  };

  return (
    <Modal className="confirm-modal-wrapper" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Czas na decyzję ..</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-attention-text">Uwaga!</p>
        <Icon iconName={IconTypes.OkDropSVG} size={70} />
        {modalText}
      </Modal.Body>
      <Modal.Footer>
        <AppButton customClass="cancel-button" title={cancelTitle} onClick={onClose} />
        <AppButton title={confirmTitle} onClick={handleChange} />
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ConfirmModal);
