import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { AppRadioOption } from "./AppRadio";
import AppSearchInput from "./AppSearchInput";
import Icon, { IconTypes } from "./Icon";

type FilterProp = {
  query: string;
  filterOptions: AppRadioOption[];
  setQuery: any;
  onChange: any;
  selectedFilteredOption: string;
};

const Filter = ({ filterOptions, query, setQuery, selectedFilteredOption, onChange }: FilterProp) => {
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);

  const handleSearchQuery = (queryValue: string): void => {
    setQuery(queryValue);
  };

  const handleFilterChange = (e: any): void => {
    setShowFilterOptions(false);
    onChange(e.target.value);
  };

  const showFilterOptionName = (): React.ReactNode => {
    if (selectedFilteredOption && filterOptions.length) {
      const filteredOptionName = filterOptions.filter(
        (option: AppRadioOption) => option.value === selectedFilteredOption,
      )[0].name;
      return <span className="selected-filter-option-text">sortowanie: ({filteredOptionName})</span>;
    }
  };

  const getFilterOptionView = (): React.ReactNode => {
    const options = filterOptions.map((option: AppRadioOption, index: number) => {
      return (
        <Form.Check
          key={`single-radio-option-${index}`}
          type="radio"
          onChange={handleFilterChange}
          value={option.value}
          id={option.value}
          name={"filter-option"}
          label={option.name}
        />
      );
    });

    return <div className={`filter-option-view ${showFilterOptions ? "show" : ""}`}>{options}</div>;
  };

  return (
    <div className="filter-wrapper">
      <div className="search-input-wrapper">
        <Icon iconName={IconTypes.SearchSVG} size={32} />
        <AppSearchInput
          placeholder="Wpisz frazę"
          onChange={(e: any) => handleSearchQuery(e.target.value)}
          value={query}
        />
      </div>
      <div className="filter-by-wrapper">
        {showFilterOptionName()}
        <div className="filter-icon-wrapper" onClick={() => setShowFilterOptions(!showFilterOptions)}>
          {!showFilterOptions && <Icon iconName={IconTypes.FilterSVG} size={23} />}
          {showFilterOptions && <Icon iconName={IconTypes.CloseSVG}size={30}  />}
        </div>
        {getFilterOptionView()}
      </div>
    </div>
  );
};

export default Filter;
