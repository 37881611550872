import { BaseInterface } from "./base";

export interface GameInterface extends BaseInterface {
  name: string;
  dropCountLimit: number | null;
  dropCountRequired: number | null;
  hourCountToCollectDrops: number | null;
  status?: StatusEnum;
}

export enum StatusEnum {
  NEW = "NEW",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}

export interface GameStatusInterface {
  status: StatusEnum;
}
