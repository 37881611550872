import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { AppStorageTypes } from "./store";

export const getAuthToken = (): string | null => localStorage.getItem(AppStorageTypes.TOKEN);
export const baseUrl = "https://api-zwik.kopettimakarone.usermd.net/";
// export const baseUrl = "http://172.20.10.2:3001/";

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
