import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../common/components/AppButton";
import Icon, { IconTypes } from "../../common/components/Icon";
import ImageModal from "../../common/components/ImageModal";
import QrCodeModal from "../../common/components/QrCodeModal";
import { LocalizationInterface, LocalizationTypeEnum } from "../../interfaces/localization";
import { PhotoInterface } from "../../interfaces/photo";
import { useDeleteLocationMutation } from "./localizationApi";
import { GameInterface, StatusEnum } from "../../interfaces/game";

type LocaliazationProp = {
  localization: LocalizationInterface;
  index: number;
  currentGame?: GameInterface;
};

const LocalizationCard = ({ localization, index, currentGame }: LocaliazationProp) => {
  const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [imageModalSrc, setImageModalSrc] = useState<string>("");
  const [deleteLocation] = useDeleteLocationMutation();

  const navigate = useNavigate();
  let { gameId } = useParams();

  const getCodeQRInfoView = (): React.ReactNode => {
    if (localization.localizationType === LocalizationTypeEnum.QR_CODE) {
      return (
        <div className="qr-code-info-wrapper">
          <span className="need-qr-code">TAK</span>

          <div onClick={() => setShowQrCodeModal(true)}>
            <Icon iconName={IconTypes.DownloadSVG} />
          </div>
          {localization.id && (
            <QrCodeModal
              qrCodeValue={localization.id}
              show={showQrCodeModal}
              qrCodeSize={256}
              onClose={() => setShowQrCodeModal(false)}
            />
          )}
        </div>
      );
    } else {
      return <span className="no-need-qr-code">NIE</span>;
    }
  };

  const handleShowLocalizationPhoto = (localizationPhoto: PhotoInterface): void => {
    setImageModalSrc(localizationPhoto.image.toString());
    setShowImageModal(true);
  };

  const handleRemoveLocalization = (): void => {
    if (localization && localization.id) {
      deleteLocation(localization.id);
    }
  };

  const getLocationPhotos = (): React.ReactNode => {
    if (!localization.photos) {
      return;
    }

    if (localization.photos && localization.photos.length) {
      return (
        <div className="localization-photos-wrapper">
          <ImageModal imageSrc={imageModalSrc} show={showImageModal} onClose={() => setShowImageModal(false)} />
          {localization.photos.map((localizationPhoto: PhotoInterface, photoIndex: number) => {
            return (
              <div
                key={`localization-photo-icon-${photoIndex}`}
                onClick={() => handleShowLocalizationPhoto(localizationPhoto)}
              >
                <Icon iconName={IconTypes.ImageSVG} />
              </div>
            );
          })}
        </div>
      );
    }
  };

  const getActionButtonsView = () => {
    if (currentGame && currentGame.status === StatusEnum.NEW) {
      return (
        <td>
          <div onClick={() => navigate(`/game/${gameId}/localization/${localization.id}`)}>
            <AppButton customClass="action-button" title="Edytuj" />
          </div>
          <div onClick={handleRemoveLocalization}>
            <AppButton customClass="action-button delete" title="Usuń" />
          </div>
        </td>
      );
    }

    return (
      <td>
        <div onClick={() => navigate(`/game/${gameId}/localization/${localization.id}`)}>
          <AppButton customClass="action-button" title="pokaż" />
        </div>
      </td>
    );
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{localization.title}</td>
      <td>{localization.coordinates}</td>
      <td>{localization.createdAt}</td>
      <td>{getCodeQRInfoView()}</td>
      <td>{getLocationPhotos()}</td>
      {getActionButtonsView()}
    </tr>
  );
};

export default LocalizationCard;
