import { BaseInterface } from "./base";
import { PhotoInterface } from "./photo";
import { RiddleInterface } from "./riddle";

export interface LocalizationInterface extends BaseInterface {
  gameId?: number;
  title: string;
  description: string;
  qrCode?: string;
  distance?: number | null;
  coordinates: string;
  localizationType: LocalizationTypeEnum,
  photos: PhotoInterface[],
  riddles: RiddleInterface[]
}

export enum LocalizationTypeEnum {
  QR_CODE = 'QR_CODE',
  IN_NEAR = 'IN_NEAR',
}
