import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../common/components/AppButton";
import { LocalizationInterface } from "../../interfaces/localization";
import { useLazyGetLocaliaztionsQuery } from "./localizationApi";
import LocalizationCard from "./LocalizationCard";
import { AppStorageTypes } from "../../app/store";
import { GameInterface, StatusEnum } from "../../interfaces/game";

const LocalizationList = () => {
  let { gameId } = useParams();
  const navigate = useNavigate();

  const [currentGame, setCurrentGame] = useState<GameInterface>();
  const [localizations, setLocalizations] = useState<LocalizationInterface[]>([]);
  const [getLocalizations, { data: localizationsData, error: getLocalizationsError }] = useLazyGetLocaliaztionsQuery();

  useEffect(() => {
    const gameData: string | null = localStorage.getItem(AppStorageTypes.CURRENT_GAME);
    if (gameData) {
      const currentGame: GameInterface = JSON.parse(gameData);
      setCurrentGame(currentGame);
    }
  }, []);

  useEffect(() => {
    if (gameId) {
      getLocalizations(gameId);
    }
  }, [gameId]);

  useEffect(() => {
    if (localizationsData && localizationsData.documents) {
      setLocalizations(localizationsData.documents);
    }
  }, [localizationsData]);

  const getLocalizationsView = (): React.ReactNode => {
    if (!localizations || !localizations.length) {
      return (
        <tr>
          <td colSpan={7}>Brak wyników</td>
        </tr>
      );
    }

    return localizations.map((localization: LocalizationInterface, index: number) => {
      return (
        <LocalizationCard
          key={`single-localization-${index}`}
          localization={localization}
          index={index}
          currentGame={currentGame}
        />
      );
    });
  };

  const getAddLocalizationButtonView = () => {
    if (currentGame && currentGame.status === StatusEnum.NEW) {
      return (
        <div onClick={() => navigate(`/game/${gameId}/localization`)} className="add-localization-button-wrapper">
          <AppButton title="Dodaj kolejną lokalizację" />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="localizations-wrapper">
      {getAddLocalizationButtonView()}
      <Table bordered>
        <thead>
          <tr>
            <th>Lp</th>
            <th>Nazwa punktu</th>
            <th>Lokalizacja na mapie</th>
            <th>Data dodania</th>
            <th>Wymagany kod QR?</th>
            <th>Zdjęcia punktu</th>
            <th>Akcja</th>
          </tr>
        </thead>
        <tbody>{getLocalizationsView()}</tbody>
      </Table>
    </div>
  );
};

export default LocalizationList;
