import React from "react";
import { Form } from "react-bootstrap";
import Icon, { IconTypes } from "./Icon";

type AppTextareaProp = {
  name: string;
  label: string;
  register: any;
  placeholder?: string;
  spacing?: number;
  customClass?: string;
  error?: string | null;
  value?: string;
  rows?: number;
};

const AppTextarea = ({
  name,
  label,
  register,
  placeholder,
  spacing = 3,
  customClass,
  error,
  value,
  rows = 3,
}: AppTextareaProp) => {
  const { onChange } = register(name);
  return (
    <Form.Group className={`app-textarea mb-${spacing} ${customClass} ${error ? "has-error" : ""}`}>
      <Form.Label>{label}</Form.Label>
      <div className="textarea-wrapper">
        <Form.Control
          {...register(name)}
          placeholder={placeholder}
          name={name}
          as="textarea"
          value={value}
          rows={rows}
        />
        {error && <p className="error-text">{error}</p>}
        {!!value && (
          <div className="clear-input-icon-wrapper" onClick={() => onChange(name, "")}>
            <Icon iconName={IconTypes.CloseSVG} />
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default AppTextarea;
